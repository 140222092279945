@use "./config";

#profile{
    margin-bottom: 0px;

    .devider{
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 2px;
        @include config.gradient-bg;
    }
    .first{
        // margin-bottom: 20px;
    }
    .common-mb{
        margin-bottom: 30px;
    }
    .wrapper-schedule{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 2px;
        .day{
            min-width: 84px;
            border: 1px solid #fff;
            padding-bottom: 10px;
            text-align: center;
            line-height: 18px;
        }
        time{
            display: block;
            text-align: center;
            padding: 7px 0;
            margin-bottom: 10px;

            color: config.$color-primary;
            background-color: #fff;
        }
    }
    #btn-reserve{
        display: block;
        width: 100%;
        padding: 15px 10px;
        margin-bottom: 60px;

        text-align: center;
        border-radius: 4px;
        background-color: #000;
        color: #fff;
        font-weight: bold;
        transition-duration: .6s;
        border: 1px solid config.$color-primary;
        a{
            display: block;
        }
        &:hover{
            color: config.$color-primary;
            background: #fff;
            transition-duration: .6s;
        }
    }
    .wrapper-link{
        padding: 0 10px;
        margin-bottom: 20px;
        &:nth-last-of-type(1){
            margin-bottom: 60px;
        }
    }
    .btn-link{
        display: block;
        width: 100%;
        padding: 15px 10px;

        text-align: center;
        border-radius: 15px;
        color: #fff;
        font-weight: bold;
        transition-duration: .6s;
        border: 1px solid #000;

        color: #000;
        @include config.gradient-bg-btn;

        &:hover{
            background: #fff;
            transition-duration: .6s;
            color: config.$color-primary;     
            border: 1px solid config.$color-primary;
        }
        &.reserve {
            background: #b40005;
            border: 1px solid #b40005;
            color: #ffffff;
            margin-top: 10px;
            &:hover{
                opacity: 0.5;
                transition-duration: .6s;
                cursor: pointer;
            }
        }
    }

    // 日記
    .diary-wrapper{
        padding: 20px 2px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        gap: 2px;
        .content{
            border: 1px solid config.$color-bg;
            max-width: 300px;
            h3{
                padding: 5px;
                background-color: #fff;
                font-size: 16px;
            }
            height: fit-content;

        }
        .wrapper-img{
            max-height: 175px;
            overflow: hidden;
        }
        video{
            max-width: 100%;
            width: 100%;
        }
    }
}

