@use "./config";


/* ヘッダーフッターほか共通のパーツ類 */

.wrapper-title{
    padding: 10px;
    margin-bottom: 20px;
    @include config.gradient-bg();
    .title{
        font-size: config.$base-font-size;
        color: #949700;
        &::before{
            content: "";
            border-left: 5px solid #949700;
            padding-right: 10px;
        }
    }

}

/* スケジュール系 */
.box-schedule{

    .box-calender{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 20px;
        a:hover {
            opacity: 0.5; 
            cursor: pointer; 
            transition: 0.6s; 
        }
    }

    .day{
        width: 70px;
        height: 70px;
        border-radius: 50%;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        text-align: center;
        border: 1px solid config.$color-primary;
        background-color: #fff;
        margin: 2px;

        &.selected{
            // 後でショップカラー作って差し替える
            @include config.gradient-bg;
            color: config.$color-primary;
            border: none;
        }
    }

    .week{
        display: block;
        width: 100%;

    }

    // 土日の色変え
    .week-6{
        color: blue !important;
    }
    .week-0{
        color: red !important;
    }

}

.box-casts{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media  screen and (max-width:490px) {
        grid-template-columns: repeat(2, 1fr);
    }

    gap: 2px;
    margin-bottom: 15px;

    .cast-card{
        background-color: #fff;
        text-align: center;

        .sc-time{
            display: block;
            text-align: center;
            padding-bottom: 5px;
            font-size: 14px;
        }
        .info{
            padding: 0 5px;
            font-size: 14px;
        }
        .name{
            color: config.$color-primary;
            font-size: 16px;
        }

        &:hover{
            img{
                opacity: .6;
            }
        } 

    }
}

/* ヘッダー */
#header-global{

    .devider{
        height: 5px;
        @include config.gradient-bg;
    } 

    // ロゴ
    h1{
        padding: 15px 15px;
        // @include config.gradient-bg;
        a:hover {
            opacity: 0.5; 
            cursor: pointer; 
            transition: 0.6s; 
        }
    }
}

#header-block-nav{
    $block-height: 40px;

    height: $block-height;
    display: grid;    
    grid-template-columns: repeat(4, 1fr);
    background-color: #fff;

    margin-bottom: 0;
    
    li{
        height: $block-height;
        line-height: $block-height;
        text-align: center;

        border-right: 1px solid config.$color-primary;
        &:nth-last-of-type(1){
            border: none;
        }
        a{
            display: block;
            &:hover {
                color: #4f69ff;
                opacity: 0.5; 
                cursor: pointer; 
                transition: 0.6s; 
            }
        }
    }

}

#header-text-nav{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;

    li{
        &::after{
            content: "|";
            padding: 0 5px;

        }
        &:nth-last-of-type(1)::after{
            content: "";
            padding: 0;
        }
        a:hover {
            color: #4f69ff;
            opacity: 0.5; 
            cursor: pointer; 
            transition: 0.6s; 
        }
    }
}



/* 以下フッター */

// 予約ボタン等
#footer-stickey{
    position: fixed;
    width: 100%;
    max-width: config.$container-width ;
    bottom: 50px;

    &>nav{
        display: block;
        max-width: fit-content;
        margin: 0 auto;

    }
}


// ページ下部フッター
#footer-global{
    .first{
        padding: 25px 15px;
        @include config.gradient-bg;
    }
    nav{
        margin-bottom: 15px;
        li{
            border-bottom: 1px solid config.$color-primary;
            &:nth-last-of-type(1){
                border: none;
            }

            a{
                padding: 15px 10px;
                display: block;
                position: relative;
                
                // 飾り矢印
                &::after{
                    content: ">";
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 25px;
                    height: 100%;

                    // 中央に配置
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: center;

                    border-left: 1px solid config.$color-primary;
                    color: config.$color-primary;
                    font-weight: bold;
                }
                
            }
            &:hover{
                @include config.gradient-bg;
                color: config.$color-primary;
                a::after{
                    background: #fff;
                
                }                
            }
        }
    }
    .end{
        padding-bottom: 20px;
        a:hover {
            opacity: 0.5; 
            cursor: pointer; 
            transition: 0.6s; 
        }
    }
}
#box-tel{
    position: fixed;
    right: 25px;
    bottom: 25px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid config.$color-primary;
    a{
        display: block;
        padding: 8px;
        &:hover {
            opacity: 0.5; 
            cursor: pointer; 
            transition: 0.6s; 
        }
    }
    box-shadow: 0px 0px 8px 1px #4d4d4d;
}

@media screen and (max-width: 767px) {
    #box-tel{
        display: none;
    }
    #footer-global .first {
        padding: 25px 15px 25px;
    }
}
