@use "_config";

.text-center{
    text-align: center;
}
.text-primary{
    color: config.$color-primary;
}

.underline{
    text-decoration: underline;
}
.common-bg{
    background-color: config.$color-bg;
}
.bg-gradient{
        @include config.gradient-bg();
}


// ロゴ共通
.logo-sm{
    max-width: 220px;
}

/*
.sp{
    display: block;
}
*/