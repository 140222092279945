
$container-width: 640px;
$block-margin: 10px;
$base-font-size: 18px;
$color-bg: rgba(255,255,255, .8);
$color-primary: #949700;

@mixin gradient-bg {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fdffdb+0,d2d69e+87,d2d69e+100 */
    background: #fdffdb; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #fdffdb 0%, #d2d69e 87%, #d2d69e 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #fdffdb 0%,#d2d69e 87%,#d2d69e 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #fdffdb 0%,#d2d69e 87%,#d2d69e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdffdb', endColorstr='#d2d69e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}

@mixin gradient-bg-btn {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fdffdb+0,d2d69e+87,d2d69e+100 */
    background: #ffffff; /* Old browsers */
    background: linear-gradient(135deg, #ffffff 0%, #cfcfcf 68%, #f9f9f9 100%);
    background: -moz-linear-gradient(-45deg,  #ffffff 0%, #cfcfcf 68%, #f9f9f9 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #ffffff 0%,#cfcfcf 68%,#f9f9f9 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #ffffff 0%,#cfcfcf 68%,#f9f9f9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f9f9f9',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}