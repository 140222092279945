@use "./config";
@use "./utilities";


#enterence{
    margin-bottom: 80px;
    padding: 0 10px;

    header{
        margin-bottom: 30px;
        .first{
            padding: 15px;
        }
    }
    .btn-enter{
        display: inline-block;
        padding: 25px 40px;
        margin-bottom: 30px;

        border:  1px solid config.$color-primary;
        border-radius: 18px;

        transition-duration: .6s;

        
        @include config.gradient-bg;
        // color: config.$color-primary;
        font-size: 14px;
        span{
            font-weight: bold;
            font-size: 42px;
        }
        &:hover{
            background: #fff;
            transition-duration: .6s;
        }
    }
    .alert{
        margin: 0 auto 10px;
        font-size: 14px;
    }
}