@use "./config";
@use "./utilities";


@use "./enterence_";
@use "./home";
@use "./profile";
@use "./other-parts";

/* ページ少なめかつ今回全体レイアウトが決まってるので部品CSS中心. */
ul,li{
    list-style: none;
}
a{
    text-decoration: unset;
    color: inherit;
    
}

body{
    scroll-behavior: smooth;
    color: #000;
    font-size: config.$base-font-size;
    background: no-repeat;
    background-image: url("../../public/img/20230602_bg.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position-x: center;
}

img{
    max-width:100%;
    max-height: 100%;
    height: auto;
}

#container{
    position: relative;
    display: block;
    max-width: config.$container-width;
    margin: 0 auto;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}

.gmap{
    width: 100%;
    height: 400px;
    padding: 35px;
}



#access{
    margin-bottom: 30px;

    .wrapper-table{
        padding: 0 10px;
    }
    text-align: left;
    table{
        width: 100%;
        padding: 5px;

        border-collapse: collapse;
    }
    table th, table td {
        border: solid 1px config.$color-primary;
        /*実線 1px 黒*/

        padding: 6px;
      }
      table th{
        width: 20%;
        min-width: 100px;
        color: config.$color-primary;
      }

}




#system{
    margin-bottom: 30px;
    text-align: left;
    background-color: rgba(255, 255, 255, .8);

    .wrapper-table{
        padding: 10px;
        padding-top: 0;
        text-align: center;
    }

    table{
        width: 100%;
        padding: 5px;

        border-collapse: collapse;
    }
    table th, table td {
        border: solid 1px config.$color-primary;
        /*実線 1px 黒*/

        padding: 6px;
      }
      table th{
        width: 20%;
        min-width: 100px;
        color: config.$color-primary;
      }
      table td{
        text-align: center;
      }
}
.frame-hvn{
    display: block;
    min-height: 450px;
    width: 100%;
    margin-bottom: 15px;
    &.diary{
        @media screen and (min-width:490px) {
            min-height: 200px;

            
        }

    }
}

// 雄琴ガイドの埋め込み
#samurai_diary_widget{
    width: 100%;
    height: 500px;

}

.text-center a:hover {
    opacity: 0.5; 
    cursor: pointer; 
    transition: 0.6s; 
}

.footer_fixed {
    background-color: rgba(255, 255, 255, 0.8);
}

#reserve_btn {
    display: none;
}

#reserve_btn a {
    letter-spacing: 0;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.5;
    display: inline-block;
    padding: 0.5rem 1rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    letter-spacing: 0.1em;
}
#reserve_btn a.btn_tell {
    position: relative;
    padding: 0.5rem 2rem 0.5rem 1rem;
    white-space: nowrap;
}
a.btn_tell img.tel {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin: 0 10px 0 0;
}

a.btn-c {
  font-size: 1rem;
  position: relative;
  padding: 0.5rem 1rem 0.5rem 1rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  border-radius: 100vh;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#007adf),
    to(#00ecbc)
  );
}
a.btn-c:nth-of-type(1) {
  background-image: -webkit-linear-gradient(left, #007adf 0%, #00ecbc 100%);
  background-image: linear-gradient(to right, #007adf 0%, #00ecbc 100%);
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}
a.btn-c:nth-of-type(2) {
  background-image: -webkit-linear-gradient(left, #fa709a 0%, #ffd1e9 100%);
  background-image: linear-gradient(to right, #fa709a 0%, #ffd1e9 100%);
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

a.btn-c:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);

  color: #fff;
}
.copyright {
    background: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
    #reserve_btn a {
        width: 45%;
        font-size: 1rem;
    }
    .footer_fixed {
        position: fixed;
        bottom: 0;
        padding: 5px 0;
        left: 0;
        right: 0;
        z-index: 10000;
        background-color: rgba(255, 255, 255, 1);

        a.btn-c {
            position: relative;
            padding: 0.5rem 1rem 0.5rem 1rem;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            width: 50%;
            margin-bottom: 2%;
            text-align: center;
            margin: 10px auto;
        }
    }
    #reserve_btn {
        display: flex;
    }
    .copyright {
        padding-bottom: 70px;
        padding-top: 15px;
        font-size: 14px;
    }
}

#enterence {
    .bnr-short {
        max-width: 472px;
        margin: 0 auto 10px;
        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
        a, img {
            max-width: 472px;
            width: 100%;
        }
    }
}

.attention-container {
  max-width: 472px;
  margin: 0 auto 1rem;
}
.attention-box {
  background: #ffff99;
  color: #333;
  text-align: center;
  margin: 2rem auto;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 16px;
}

.bnr-box {
  max-width: 700px;
  margin: 0 auto;
}

.bnr-box img{
  max-width: 468px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.attention-wrap {
  color: #000;
  text-align: center;
}
.attention-wrap .title {
  font-size: 16px;
  font-weight: bold;
}
.attention-wrap .group-box {
  border: 1px solid #000;
  padding: 1rem;
  border-radius: 20px;
}
.group-logo-wrap {
    margin: 0 auto;
    padding: 0;
    width: 250px;
    height: 50px;
}

.group-logo-wrap a:hover {
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.6s;
}

.group-logo-wrap.-mt {
    margin: 1rem auto 0;
}